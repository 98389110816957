.container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.25);
    justify-content: center;
    align-content: center;
}
.grid{
    display: grid;
}
.none{
    display: none;
}
.loaderContainer{
    width: 10rem;
    height: 10rem;
    border-radius: 1.25rem;
    /* background-color: rgba(255,255,255,0.75); */
    /* backdrop-filter: blur(5px); */
    position: relative;
}
.dot{
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
    background-color: rgba(0,0,0,1);
    animation-duration: 3s;
    animation-iteration-count: infinite;
    position: absolute;
    left: 4.25rem;
    top: 4.25rem;
}
.dot1{
    background-color: rgba(0, 40, 85,1);
}
.dot2{
    background-color: rgba(0, 40, 85,0.9);
}
.dot3{
    background-color: rgba(0, 40, 85,0.8);
}
.dot4{
    background-color: rgba(0, 40, 85,0.7);
}
.dot5{
    background-color: rgba(0, 40, 85,0.6);
}
.dot6{
    background-color: rgba(0, 40, 85,0.5);
}
.dot7{
    background-color: rgba(0, 40, 85,0.4);
}
.dot8{
    background-color: rgba(0, 40, 85,0.3);
}

.hiq .dot1{
    background-color: var(--uk-grey);
}
.hiq .dot2{
    background-color: var(--uk-grey);
    opacity: 0.9;
}
.hiq .dot3{
    background-color: var(--uk-grey);
    opacity: 0.8;
}
.hiq .dot4{
    background-color: var(--uk-grey);
    opacity: 0.7
}
.hiq .dot5{
    background-color: var(--uk-grey);
    opacity: 0.6
}
.hiq .dot6{
    background-color: var(--uk-grey);
    opacity: 0.5
}
.hiq .dot7{
    background-color: var(--uk-grey);
    opacity: 0.4
}
.hiq .dot8{
    background-color: var(--uk-grey);
    opacity: 0.3
}
.dotAnimation8{
    animation-name: dotAnimation8;
    animation-timing-function: linear;
}
@keyframes dotAnimation8 {
    from { 	transform: rotate(0deg) translateX(3rem) rotate(0deg); }
	to   {  transform: rotate(360deg) translateX(3rem) rotate(-360deg); }
}

.dotAnimation7{
    animation-name: dotAnimation7;
    animation-timing-function: linear;
}
@keyframes dotAnimation7 {
    from { 	transform: rotate(45deg) translateX(3rem) rotate(45deg); }
	to   {  transform: rotate(405deg) translateX(3rem) rotate(-405deg); }
}

.dotAnimation6{
    animation-name: dotAnimation6;
    animation-timing-function: linear;
}
@keyframes dotAnimation6 {
    from { 	transform: rotate(90deg) translateX(3rem) rotate(90deg); }
	to   {  transform: rotate(450deg) translateX(3rem) rotate(-450deg); }
}

.dotAnimation5{
    animation-name: dotAnimation5;
    animation-timing-function: linear;
}
@keyframes dotAnimation5 {
    from { 	transform: rotate(135deg) translateX(3rem) rotate(135deg); }
	to   {  transform: rotate(495deg) translateX(3rem) rotate(-495deg); }
}

.dotAnimation4{
    animation-name: dotAnimation4;
    animation-timing-function: linear;
}
@keyframes dotAnimation4 {
    from { 	transform: rotate(180deg) translateX(3rem) rotate(180deg); }
	to   {  transform: rotate(540deg) translateX(3rem) rotate(-540deg); }
}

.dotAnimation3{
    animation-name: dotAnimation3;
    animation-timing-function: linear;
}
@keyframes dotAnimation3 {
    from { 	transform: rotate(225deg) translateX(3rem) rotate(225deg); }
	to   {  transform: rotate(585deg) translateX(3rem) rotate(-585deg); }
}

.dotAnimation2{
    animation-name: dotAnimation2;
    animation-timing-function: linear;
}
@keyframes dotAnimation2 {
    from { 	transform: rotate(270deg) translateX(3rem) rotate(270deg); }
	to   {  transform: rotate(630deg) translateX(3rem) rotate(-630deg); }
}

.dotAnimation1{
    animation-name: dotAnimation1;
    animation-timing-function: linear;
}
@keyframes dotAnimation1 {
    from { 	transform: rotate(315deg) translateX(3rem) rotate(315deg); }
	to   {  transform: rotate(675deg) translateX(3rem) rotate(-675deg); }
}