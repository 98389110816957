.brandButton{
    cursor: pointer;
    /* background-color: blue; */
    display: grid;
    justify-items: center;
    align-items: center;
    row-gap: 0rem;
    margin: 1rem 0;
    grid-template-rows: 1fr;
    position: relative;
}
.brandButton div.svgContainer{
   aspect-ratio: 2.39;
   display: grid;
   justify-items: center;
   align-items: center;
}
.brandButton div svg{
    width: 100%;
}
.brandButton div.unselectedBar{
    height: 0.25rem;
    width: 0%;
    margin: 0 37.5%;
    background-color: #fff;
    border-radius: 0.125rem;
    opacity: 0;
    transition: 0.3s;
}
.brandButton div.selectedBar{
    opacity: 1;
    width: 50%;
    margin: 0 25%;
}
.brandButton div.disclaimer{
    position: absolute;
    bottom: -0.75rem;
    opacity: 0;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: max-content;
    padding: 0.25rem 0.75rem;
    background-color: rgb(237,237,237);
    border-radius: 0.75rem;
}
.brandButton div.disclaimer.selected{
    opacity: 1;
    background-color: rgb(237,237,237);
}
.brandButton div.disclaimer div.svgContainer2{
    width: max-content;
    padding: 0 0.5rem 0 0;
    height: 1rem;
    display: grid;
    justify-content: start;
}
.brandButton div.disclaimer svg{
    fill: rgb(255,20,20);
    height: 1rem;
    width: auto;
}
.brandButton div.disclaimer p{
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 600;
    line-height: 1rem;
    text-overflow: clip;
    overflow: hidden;
    text-align: left;
    color: rgb(0,0,0);
}
.brandButton:hover div.disclaimer{
    opacity: 1;
}
@media screen and (max-width: 1300px){
    .brandButton div.disclaimer{
        position: absolute;
        bottom: -0.75rem;
        opacity: 0;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        flex-direction: row;
        width: max-content;
        height: 1.125rem;
        padding: 0.125rem 0.5rem;
        border-radius: 0.75rem;
        background-color: transparent;
    }
    .brandButton div.disclaimer.selected{
        opacity: 1;
        background-color: transparent;
    }
    .brandButton div.disclaimer div.svgContainer2{
        padding: 0;
        height: 0.875rem;
    }
    .brandButton div.disclaimer svg{
        height: 0.875rem;
    }
    .brandButton:hover div.disclaimer{
        opacity: 1;
    }
    .brandButton div.disclaimer p{
        color: #fff;
        padding: 0 0 0 0.5rem;
        font-size: 0.75rem;
    }
}