.container{
    height: max-content;
    background-color: #fff;
    margin: 8rem 0 0 0;
    padding: 4rem calc(100cqw/12);
    color: rgba(0, 40, 85,1);
}
.hiq.container{
    background-color: #fff;
    color: var(--uk-grey);
}
.container p{
    font-size: 0.875rem;
    padding: 0 0 0 1.25rem;
    margin: 0 0 0.25rem 0;
}
.container div{
    margin: 0 0 1.25rem
}
.container ul{
    font-size: 0.875rem;
}
.container h3, .container h4, .container strong{
    color: rgba(0, 40, 85,1);
    margin: 0 0 0.25rem 0;
}
.hiq.container h3, .hiq.container h4, .hiq.container strong{
    color: var(--uk-grey);
}
.container span{
    font-style: italic;
    font-weight: 600;
}
.container ul{
    margin: 0 0 0.25rem 2.5rem;
    list-style-type: square;
}
.background{
    min-height: 50vh;
    width: 100%;
    backdrop-filter: blur(20px);
    background-size: cover;
    background-position: center;
    z-index: 0;
    opacity: 1;
}
@media screen and (max-width: 1450px){
    .container{
        margin: 6rem 0 0 0;
    }
}
@media screen and (max-width: 1000px){
    .container{
        margin: 6.5rem 0 0 0;
        padding: calc(100cqw/12) calc(100cqw/12) calc(100cqw/12) calc(100cqw/12);
    }
}