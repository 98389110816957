footer{
    color: rgba(0,40,85,1);
    background-color: rgba(237,237,237,1);
    padding: calc(100cqw/24) calc(100cqw/12);
    position: relative;
    z-index: 3;
}
footer.hiq{
    color: #fff;
    background-color: var(--uk-grey);
}
.helpCon{
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 1.5rem 1.5rem;
    align-items: center;
    margin: 0 0 1rem -2.5rem;
}
.helpCon p{
    grid-column: 2/3;
    font-size: 1.25rem;
    font-weight: 700;
}
.helpIcon{
    display: grid;
    align-items: center;
}
.helpIcon svg{
    height: 1.5rem;
}
.footerFooter{
    width: 100%;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}
footer a{
    color: rgba(0,40,85,1);
    padding: 0 2.75rem;
    display: inline-block;
    font-size: 0.875rem;
    height: 0.875rem;
    line-height: 1;
    text-decoration: none;
    text-wrap: nowrap;
}
footer a:hover{
    color: #424242;
    display: inline-block;
    text-decoration: underline;
}
footer a+a{
    border-left: 1px solid rgba(0,40,85,0.75);
}
footer.hiq a{
    color: #fff;
}
footer.hiq a:hover{
    color: rgba(255,255,255,0.75);
}
footer.hiq a+a{
    border-left: 1px solid rgba(255,255,255,0.75);
}
footer.hiq svg path{
    fill: #fff;
}
.contact{
    display: flex;
    justify-content: flex-start;
    font-style: italic;
    font-size: 1.25rem;
    font-weight: 300;
}
.links{
    display: flex;
    justify-content: center;
}
.copyright{
    display: flex;
    justify-content: flex-end;
    font-size: 0.875rem;
}
@media screen and (max-width: 900px){
    footer{
        padding: calc(100vw/9) calc(100vw/12);
        display: block;
    }
}
footer.external{
    margin: 100vh 0 0 0;
    position: static;
}

@media screen and (max-width: 1400px){
    .helpCon p{
        font-size: 1rem;
    }
    .helpIcon svg{
        height: 1.25rem;
    }
    .contact{
        font-size: 1rem;
    }
    footer a{
        padding: 0 1.5rem;
        font-size: 0.75rem;
    }
    .copyright{
        font-size: 0.75rem;
    }
}
@media screen and (max-width: 900px){
    footer.external{
        padding: calc(100vw/9) calc(100vw/12);
        display: block;
    }
    .helpCon{
        display: grid;
        grid-template-columns: 2.5rem 1fr;
        grid-template-rows: 1.5rem 1.5rem;
        align-items: center;
        margin: 0 0 0.75rem 0;
    }
    .helpCon p{
        grid-column: 2/3;
        font-size: 1rem;
        font-weight: 700;
    }
    .helpIcon{
        display: grid;
        align-items: center;
    }
    .helpIcon svg{
        height: 1.5rem;
    }
    .contact{
        font-size: 1rem;
        font-weight: 300;
        margin: 0 2.5rem 2rem 2.5rem;
    }
    .links{
        display: flex;
        flex-flow: column;
        align-content: center;
        justify-content: start;
        margin: 0 2.5rem;
    }
    footer a{
        padding: 0;
    }
    footer a+a{
        border-left: none;
        margin: 0.5rem 0 0 0;
    }
    .footerFooter{
        margin: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .copyright{
        margin: 2rem 2.5rem 0 2.5rem;
    }
    footer a{
        font-size: 0.875rem;
    }
    .copyright{
        font-size: 0.875rem;
    }
}