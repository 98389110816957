.background{
    position: absolute;
    top: 0;
    height: max-content;
    min-height: 100vh;
    width: 100%;
    backdrop-filter: blur(20px);
    background-image: url('../../imgs/home/Box__Premio_Marketing.jpg');
    background-size: cover;
    background-position: center;
    z-index: 0;
    opacity: 1;
}
.background.hiq{
    background-image: url('../../imgs/home/HiQ_Logos.jpg');
}
.overlay{
    backdrop-filter: blur(7.5px);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.paneContainer{
    height: calc(100vh - 5rem);
    margin: 2.5rem calc(200cqw/12);
    position: relative;
    z-index: 3;
    display: grid;
    justify-content: center;
    align-content: center;
}
.pane{
    border-radius: 1rem;
    width: calc(800cqw/12);
    height: max-content;
    max-height: calc(100vh - 5rem);
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-y: auto;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
}
@media screen and (max-width: 1700px){
    .hiq .pane{
        grid-template-columns: 3fr 2fr;
    }
}
.pane h1{
    color: #fff;
    text-align: center;
}
.paneUpper{
    background-color: rgba(237, 237, 237,1);
    display:grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 2.5rem;
    font-size: 1.5rem;
    color: var(--eu-blue);
    font-weight: 300;
}
.paneUpper strong{
    font-weight: 700;
}
.paneLower{
    grid-column: 2 / 3;
    width: 100%;
    padding: 2.5rem;
    background-color: #fff;
    display: grid;
    grid-template-rows: 4rem 1fr 4rem;
    align-items: start;
    color: var(--eu-blue);
}
p.brandDisclaimer{
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0;
}
.brandSelectDiv{
    margin: 0 0 1.5rem 0;
}
.hiq .paneUpper{
    background-color: var(--uk-grey);
    color: #fff;
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    align-items: center;
}
.hiq .paneUpper span {
    display: inline-block;
    line-height: 1;
}
.hiq .paneUpper div.divider{
    background-color: rgb(182, 194, 201);
    height: 3rem;
    width: 0.125rem;
    margin: 0 0 0 1.5rem;
}
.hiq .paneUpper div.svgCon {
    height: 4rem;
}
.hiq .paneUpper div.svgCon svg{
    height: 4rem;
}
.hiq .paneLower{
    color: var(--uk-grey);
}
.invalid{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}
.paneLower h2{
    color: var(--eu-blue);
    line-height: 1;
}
.hiq .paneLower h2{
    color: var(--uk-grey);
}
.paneLower .emailPane{
    display: block;
    width: 60rem;
    margin-left: auto;
    margin-right: auto;
}
.paneLower .emailPane p{
    font-size: 0.875rem;
}
.paneLower div label{
    height: auto;
    font-weight: 500;
    display: block;
    margin: 0.5rem 0 0.25rem 0;
    color: var(--eu-blue);
    font-size: 0.875rem;
}
.hiq .paneLower div label{
    color: var(--uk-grey);
}
.paneLower div input{
    display: block;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    border: none;
    background-color: rgba(237, 237, 237,1);
    color: rgb(66,66,66);
    line-height: 1;
    width: 100%;
}
.paneLower div select{
    display: block;
    height: 2rem;
    border-radius: 0.5rem;
    padding: 0.375rem;
    border: none;
    background-color: rgba(237, 237, 237,1);
    color: rgb(66,66,66);
    line-height: 1;
    width: 100%;
}
.paneLower div select:disabled{
    background-color: rgba(255, 255, 255,1);
    color: rgb(66,66,66);
}
.paneLower div input:disabled{
    opacity: 1;
}
.disabledLabel{
    opacity: 0.5;
}
.paneLower .footer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;
}
.paneLower div.footer button{
    width: max-content;
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 1.5rem 0 0 0;
    font-weight: 600;
    cursor: pointer;
    background-color: rgb(220, 220, 220);
    color:#000;
    /* box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15); */
}
.paneLower div.footer button:hover{
    opacity: 0.75;
}
.paneLower div.footer input, .paneLower div.footer button.nextBtn{
    width: max-content;
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 1.5rem 0 0 0;
    font-weight: 600;
    cursor: pointer;
    background-color: rgb(255, 205, 0);
    color:#000;
    /* box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15); */
}
.hiq .paneLower div.footer input,.hiq .paneLower div.footer button.nextBtn{
    background-color: var(--uk-cyan);
    color:#fff;
}
.paneLower div.footer input:hover, .nextBtn:hover{
    opacity: 0.75;
}
.paneLower div.footer input:disabled, .nextBtn:disabled{
    opacity: 0.25;
    cursor: context-menu;
}
/* .paneLower{
    width: 100%;
    padding: 2.5rem;
    background-color: #fff;
    display: grid;
    grid-template-rows: 4rem 1fr 4rem;
    align-items: center;
} */
.paneLower .emailPane{
    /* display: grid;
    grid-template-rows: 2rem 2rem; */
    margin-left: auto;
    margin-right: auto;
    width: 100%
}
.paneLower .passwordPane{
    /* display: grid;
    grid-template-rows: 2rem 2rem 2rem 2rem 2rem; */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.paneLower .passwordPane hr{
    border: 1px solid rgba(220,220,220,0.5);
    margin: 2rem;
}
.namesDiv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}
.paneLower div.namesDiv label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
}
.paneLower div.namesDiv label input {
    margin: 0 1rem 0 0;
}
.errorMessage{
    font-size: 0.875rem;
    padding: 0.25rem 0;
    color: red;
}
.loginPane{
    grid-template-rows: 2rem 2rem 2rem 2rem 2rem;
}
.paneLower2{
    width: 100%;
    padding: 2.5rem;
    background-color: #fff;
    display: grid;
    align-items: center;
    justify-items: center;
    color: rgb(0,40,85);
}
.acceptPolicy{
    display: flex;
    grid-column: 1/3;
    align-content: center;
}
div.acceptPolicy p{
    line-height: 1;
    margin: 0 0 0 0.5rem;
}
.paneLower div.acceptPolicy label{
    display: inline;
    font-weight: 400;
    color: rgb(0,40,85);
    padding: 0;
}
.paneLower input[type=checkbox] {
    height: 0.875rem;
    width: 0.875rem;
}

@media screen and (max-width: 1500px) {
    .pane{
        /* border: 2px solid #ddd; */
        border-radius: 1.25rem;
        margin: 2.5rem calc(100cqw/12);
        width: calc(1000cqw/12);
        height: max-content;
        display: flex;
        flex-flow: column;
    }
    .paneUpper, .hiq .paneUpper {
        display: none;
    }
}
@media screen and (max-width: 1000px) {
    .pane{
        /* border: 2px solid #ddd; */
        border-radius: 1.25rem;
        margin: 2.5rem calc(100cqw/12);
        width: calc(1000cqw/12);
        height: max-content;
        display: flex;
        flex-flow: column;
    }
    .paneUpper{
        display: none;
    }
    .paneLower{
        display: block;
    }
    .paneLower p {
        margin-top: auto;
        margin-bottom: auto;
    }
    .paneLower h2{
        margin: 0 0 1rem 0;
    }
    .paneLower .passwordPane{
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .namesDiv{
        display: block;
    }
    p.brandDisclaimer{
        font-size: 0.875rem;
        margin: 0.5rem 0 1rem 0;
    }
    .brandSelectDiv{
        margin: 0 0 1.5rem 0;
    }
    .paneLower .footer{
        display: flex;
        flex-flow: column-reverse;
    }
    .paneLower div.footer button, .paneLower div.footer input{
        width: 100%;
    }
}