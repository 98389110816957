.container{
    position: absolute;
    top: 0;
    height: 8rem;
    width: 100%;
    z-index: 4;
    /* -webkit-container-type: inline-size; 
    container-type: inline-size; */
}
.container.changerActive{
    height: 0;
}
.bannerContainer{
    top: 4rem;
    right: 0;
    position: fixed;
    height: 4.5rem;
    width: 100%;
    
    line-height: 1;
    color: #fff;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    /* border-top: 2px solid #ddd; */
    align-items: center;
    opacity: 1;
    transition: 0.3s;
    z-index: 0;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
}
.bannerContainer.noHover{
    cursor: context-menu
}
.bannerContainer.noHover:hover{
    opacity: 1;
}
.bannerContainer.changerActive{
    opacity: 0;
}
.bannerContainer p+p{
    margin-left: 2rem;
}
.pageHeading{
    display: grid;
    align-items: center;
    background-color: rgba(0, 40, 85,1);
    padding: 0 0 0 calc(100cqw/12);
    height: 4.5rem;
}
.pageHeading h1{
    color: #fff;
    font-size: 1.25rem;
    line-height: 1;
}
.hiq .pageHeading{
    background-color: #fff;
}
.hiq .pageHeading h1{
    color: var(--uk-grey);
    font-size: 1.25rem;
    line-height: 1;
}
.selectedContent{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-content: center;
    height: 4.5rem;
    background-color: rgba(0, 40, 85,1);
    padding: 0 calc(100cqw/12) 0 0;
    cursor: pointer;
}
.selectedContent:hover{
    opacity: 0.85;
}
.selectedContent:hover .hoverText, .selectedContent:hover .brandCountry{
    opacity: 1;
}
.text{
    color: rgba(0,0,0,0.7);
    font-size: 0.75rem;
    padding: 0.125rem 0;
    margin: 0.75rem 0;
}
.hoverText{
    display: grid;
    align-content: center;
    opacity: 0;
    transition: 0.3s;
    font-size: 0.75rem;
    padding: 0.875rem 1.75rem;
    height: 4.5rem;
}
.bannerContainer:hover .hoverText{
    opacity: 1;
}
.brandCountry{
    color: rgba(255,255,255,1);
    background-color: rgba(0, 40, 85,1);
    padding: 0;
    display: flex;
    align-items: center;
}
.brandCountry:hover{
}
.brandCountry span{
    font-weight: 600;
}
.logoContainer{
    height: 2.5rem;
}
.logoContainer svg{
    height: 2.5rem;
}
.logoContainer.supSer{
    height: 3rem;
}
.logoContainer.supSer svg{
    height: 3rem;
}
.brandCountryTextCon{
    display: flex;
}
.countryText{
    font-weight: 500;
}
.flagCon{
    display: none;
}
.langText{
    font-weight: 300;
    font-style: italic;
    padding: 0.75rem 0;
}
.changerContainer{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    /* background-color: rgba(255,0,0,0.5); */
    backdrop-filter: blur(20px);
    z-index: 0;
    opacity: 0;
    transition: 0.3s;
}
.changerContainer.changerActive{
    opacity: 1;
}
.changerPane{
    margin: 10rem calc(200cqw/12) 0 calc(200cqw/12);
    width: calc(800cqw/12);
    background-color: rgba(0, 40, 85,1);
    border-radius: 0.75rem;
    padding: 2.5rem;
    display: none;
    opacity: 0;
    transition: 0s;
}
.changerPane.changerActive{
    opacity: 1;
    margin: 8rem calc(200cqw/12) 20vh calc(200cqw/12);
    width: calc(800cqw/12);
    /* height: 60vh; */
    display: flex;
    grid-template-rows: 1.5rem 1fr 1.5rem 1fr 1.5rem 1fr 2.5rem;
    flex-flow: column nowrap;
    transition: 0.3s;
}
.changerPane h2{
    color: #fff;
}
.footer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: end;
}
.footer button{
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    cursor: pointer;
}
.brandSelectCon, .countrySelectCon{
    padding: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
}
.brandButton{
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background-color: #eee;
    cursor: pointer;
}
.brandButton:hover{
    background-color: #ddd;
}
.brandButton+.brandButton{
    margin-left: 2rem
}
.brandButton.selected{
    background-color: rgba(0, 40, 85,1);
    color: #fff;
}
.countryButton{
    height: 2.5rem;
    margin: 1rem 0;
    padding: 0.5rem 0.5rem;
    border-radius: 1.25rem;
    color: #fff;
    cursor: pointer;
    display: flex;
    /* opacity: 0.5; */
    background-color: transparent;
}
.countryButton:hover{
    color: #fff;
    background-color: rgba(38, 72, 110 ,1);
}
.countryButton+.countryButton{
    margin-left: 0.5rem
}
.countryButton.selected{
    /* background-color: rgba(0, 40, 85,1); */
    background-color: #fff;
    color: rgba(0, 40, 85,1);
    opacity: 1;
}
.flagContainer{
    width: 1.5rem;
    height: 1.5rem;
    /* background-color: #000; */
    display: grid;
    justify-content: center;
    align-content: center;
    margin: 0 0.5rem 0 0;
}
.countryButton p{
    font-weight: 600;
    line-height: 1rem;
    padding: 0.25rem 0.25rem 0.25rem 0;
}
.disable{
    display: none;
}
@media screen and (max-width: 1450px){
    .container{
        height: 6.5rem;
    }
    .bannerContainer{
        top: 3rem;
        right: 0;
        position: fixed;
        height: 3rem;
        width: 100%;
        line-height: 1;
        color: #fff;
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-content: center;
        /* border-top: 2px solid #ddd; */
        align-items: center;
        opacity: 1;
        transition: 0.3s;
        z-index: 2;
    }
    .pageHeading{
        display: grid;
        height: 3rem;
    }
    .pageHeading h1{
        align-content: center;
        color: #fff;
        font-size: 1rem;
        line-height: 1;
        height: auto;
    }
    .changerPane h2{
        font-size: 1.125rem;
    }
    .selectedContent{
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-content: center;
        height: 3rem;
        background-color: rgba(0, 40, 85,1);
        padding: 0 calc(100cqw/12) 0 0;
        cursor: pointer;
    }
    .hoverText{
        display: grid;
        align-content: center;
        opacity: 0;
        transition: 0.3s;
        font-size: 0.75rem;
        padding: 0.875rem 1.75rem;
        height: 3rem;
    }
    .bannerContainer:hover .hoverText{
        opacity: 1;
    }
    .brandCountry{
        color: rgba(255,255,255,1);
        background-color: rgba(0, 40, 85,1);
        padding: 0;
        display: flex;
        align-items: center;
    }
    .logoContainer{
        height: 2rem;
        border-radius: 0.5rem;
        overflow: hidden;
        /* background-color: rgba(0, 40, 85,1); */
    }
    .logoContainer svg{
        height: 2rem;
    }
    .logoContainer.supSer{
        height: 2rem;
    }
    .logoContainer.supSer svg{
        height: 2rem;
    }
    .countryText{
        font-weight: 500;
        font-size: 0.875rem;
    }
/* }
@media screen and (max-width: 1300px){ */
    .changerPane.changerActive{
        margin: 5rem calc(100cqw/12) 20vh calc(100cqw/12);
        width: calc(1000cqw/12);
    }
    .brandSelectCon{
        padding: 2rem;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-content: center;
     }
    .brandSelectCon div{
        width: 80%;
        display: grid;
        justify-items: center;
     }
    .countryButton{
        height: 2.25rem;
        margin: 0.25rem 0;
        padding: 0.375rem 0.375rem;
        border-radius: 1.25rem;
        cursor: pointer;
        display: flex;
    }
    .countryButton+.countryButton{
        margin-left: 0.5rem;
    }
}
@media screen and (max-width: 1000px){
    .bannerContainer{
        display: flex;
        row-gap: 0.5rem;
        height: 3.5rem;
        justify-content: space-between;
        align-content: center;
        top: 3rem;
        padding: 0.5rem 0;
        background-color:#fff;
    }
    .pageHeading{
        background-color: #fff;
        height: 100%;
        padding-right: 1rem;
    }
    .pageHeading h1{
        font-size: 1rem;
        color: rgba(0, 40, 85,1);
        height: 100%;
    }
    .selectedContent{
        padding: 0 0;
        justify-content: end;
        background-color: #fff;
        flex-flow: row-reverse;
        height: 100%;
        /* margin: 0.5rem calc(100cqw/12); */
    }
    .hoverText{
        display: none;
    }
    .brandCountry{
        margin: 0 calc(100cqw/12) 0 0;
        height: 2.5rem;
        background-color: rgba(0, 40, 85,1);
        border-radius: 1.25rem;
        overflow: hidden;
    }
    .logoContainer{
        height: 2.5rem;
        border-radius: 0.5rem;
        padding: 0.25rem 0;
        overflow: hidden;
        /* background-color: rgba(0, 40, 85,1); */
    }
    .logoContainer svg{
        height: 2rem;
    }
    .logoContainer.supSer{
        height: 2.5rem;
    }
    .logoContainer.supSer svg{
        height: 2rem;
    }
    .brandCountryTextCon{
        flex-flow: column;
    }
    .countryText{
        display: none;
        font-size: 0.75rem;
        padding: 0.875rem 0.875rem 0.875rem 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .flagCon{
        display: block;
        margin: 0 0.5rem 0 0.375rem;
    }
    /* .langText{
        font-size: 0.75rem;
        padding: 0.0625rem 0 0.4375rem 0;
    } */
    /* ------------------------------------------------------ */
    .changerPane{
        margin: 0;
        background-color: rgba(0, 40, 85,1);
        display: none;
    }
    .changerPane.changerActive{
        border-radius: 1.25rem;
        margin: 5rem calc(100cqw/12) 0 calc(100cqw/12);
        padding: 1.25rem;
        width: calc(1000cqw/12);
        display: flex;
        grid-template-rows: 1.5rem 1fr 1.5rem 1fr 1.5rem 1fr 2.5rem;
        flex-flow: column nowrap;
    }
    .changerPane h2{
        color: #fff;
        font-size: 1.125rem;
    }
    .footer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: end;
    }
    .footer button{
        border-radius: 1.25rem;
        border: none;
        height: 2.5rem;
        line-height: 1rem;
        padding: 0.75rem 1.5rem;
        font-weight: 600;
        cursor: pointer;
        color: #000;
        background-color: rgb(255, 205, 0);
    }
    .footer button:hover{
        opacity: 0.75;
        color: #000;
    }
    .brandSelectCon{
        flex-flow: row wrap;
        padding: 1.25rem 0;
    }
    .countrySelectCon{
        padding: 1.25rem 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
    }
    .brandSelectCon div{
       width: 80%;
       max-width: 300px;
       display: grid;
       justify-items: center;
    }
    .countryButton{
        height: 2.25rem;
        margin: 0.25rem 0;
        padding: 0.375rem 0.375rem;
        border-radius: 1.25rem;
        cursor: pointer;
        display: flex;
    }
    .countryButton+.countryButton{
        margin-left: 0.5rem;
    }
    .countryButton.selected{
        /* background-color: rgba(0, 40, 85,1); */
        color: rgba(0, 40, 85,1);
        opacity: 1;
    }
    .flagContainer{
        width: 1.5rem;
        height: 1.5rem;
        /* background-color: #000; */
        display: grid;
        justify-content: center;
        align-content: center;
        margin: 0 0.5rem 0 0;
    }
    .countryButton p{
        font-weight: 600;
        line-height: 1rem;
        padding: 0.25rem 0.25rem 0.25rem 0;
    }
}
@media (orientation: landscape) and (max-width: 1200px) {
    .changerPane{
        margin: 0;
        background-color: rgba(0, 40, 85,1);
        display: none;
    }
    .changerPane.changerActive{
        border-radius: 0rem;
        margin: 3rem 0 0 0;
        padding: 1rem;
        width: 100%;
        display: flex;
        grid-template-rows: 1.5rem 1fr 1.5rem 1fr 1.5rem 1fr 2.5rem;
        flex-flow: column nowrap;
    }
    .brandSelectCon{
        justify-content: center;
        flex-flow: row nowrap;
        padding: 1rem 0;
    }
    .brandSelectCon div{
        max-width: 300px;
        display: grid;
        justify-items: center;
    }
    .changerPane h2{
        color: #fff;
        opacity: 0.75;
        font-size: 1rem;
        /* font-weight: 500; */
    }
    .countrySelectCon{
        padding: rem 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
    }
    .countryButton{
        height: 2rem;
        margin: 0.25rem 0;
        padding: 0.25rem 0.25rem;
        border-radius: 1.125rem;
        cursor: pointer;
        display: flex;
    }
    .countryButton+.countryButton{
        margin-left: 0.5rem;
    }
    .countryButton p{
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        padding: 0.25rem 0.25rem 0.25rem 0;
    }
    .footer button{
        border-radius: 1rem;
        border: none;
        height: 2rem;
        line-height: 1rem;
        padding: 0.5rem 1.5rem;
        font-weight: 600;
        cursor: pointer;
    }
}
@media screen and (max-width: 500px){
    .brandSelectCon{
        margin: 0 0 1rem 0;
    }
    .brandSelectCon div{
        width: 80%;
        max-width: 300px;
        display: grid;
        justify-items: center;
        margin: 0;
    }
}