.container{
    border: 2px solid #fff;
    background-color: #fff;
    overflow: hidden;
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
}
.container svg{
    display: block;
    height: 100%;
    width: 100%;
}
.container.selected{
    border: 2px solid rgba(0, 40, 85,1);
    background-color: rgba(0, 40, 85,1);
}