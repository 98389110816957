/* .buttonMain:hover .contentAnimation {
    animation-name: scrollRestStart, scrollForward, scrollRestEnd, scrollBackward;
    animation-timing-function: linear, linear, linear, linear;
    animation-duration: 2s, var(--move-duration,4s), 2s, var(--move-duration,4s);
    animation-delay: 0s, 2s, calc(var(--move-duration,4s) + 2s), calc(var(--move-duration,4s) + 4s);
    animation-iteration-count: 1;
} 
@keyframes scrollRestStart {
    0% {transform: translateX(0);}
    100% {transform: translateX(0);}
}
@keyframes scrollForward {
    0% {transform: translateX(0);}
    100% {transform: translateX(calc(var(--container-width, 100%) - var(--content-width, 100%)));}
}
@keyframes scrollRestEnd {
    0% {transform: translateX(calc(var(--container-width, 100%) - var(--content-width, 100%)));}
    100% {transform: translateX(calc(var(--container-width, 100%) - var(--content-width, 100%)));}
}
@keyframes scrollBackward {
    0% {transform: translateX(calc(var(--container-width, 100%) - var(--content-width, 100%)));}
    100% {transform: translateX(0);}
} */
.container{
    margin: 8rem 0 0 0;
    padding: 4rem calc(100cqw/12) 4rem calc(100cqw/12);
}
.buttonContainer{
    min-height: 75vh;
    background-color: rgba(255,255,255,1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    position: relative;
    z-index: 1;
}
iframe{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 75vh;
}
.buttonMain{
    /* display: grid;
    grid-template-rows: 1fr 4.75rem; */
    background-color: transparent;
    margin: 0rem;
    font-size: 1rem;
    transition: 0.3s;
    cursor: pointer;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    position: relative;
}
.buttonMain:hover{

}

.buttonMain p{
    color: #424242;
}
.buttonMain h2{
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1;
    margin: 0 0 0.25rem 0;
}
.buttonMain p{
    line-height: 1;
    font-size: 0.9375rem;
    transition: 0.3s;
}
.imgContainer{
    width: 100%;
    height: calc(100% - 4.75rem);
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    image-rendering: smooth;
    transition: 0.3s;
}
.buttonMain:hover .imgContainer{
    height: 100%;
}
.imgOverlay2{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.3s;
    background-color: rgba(0, 0, 0,0);
    backdrop-filter: blur(0px);
    opacity: 0;
    display: grid;
    grid-template-columns: 0.25rem 1fr;
    padding: 2.5rem;
    align-content: center;
    align-items: center;
    z-index: 0
}
.imgOverlay{
    position: absolute;
    width: 100%;
    height: calc(100% - 4.75rem);
    transition: 0.3s;
    backdrop-filter: blur(0px);
    opacity: 0;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1.25rem 2.5rem 1.875rem 2.5rem;
    align-content: end;
    align-items: end;
    z-index: 1;
}
.imgOverlay .expandingBar{
    width: 0.125rem;
    height: 0%;
    background-color: rgb(237,237,237,0.5);
    transition: 0.9s;
}
.expandingBar2{
    position: absolute;
    bottom: 4.875rem;
    height: 0.125rem;
    width: 0;
    left: 0;
    transition: 0.3s;
}
.buttonMain:hover .imgOverlay{
    opacity: 1;
}
.buttonMain:hover .imgOverlay2{
    opacity: 1;
    background-color: rgb(0,40,85,0.75);
    backdrop-filter: blur(10px);
}
.hiq.buttonMain:hover .imgOverlay2{
    opacity: 1;
    background-color: var(--uk-grey-75);
    backdrop-filter: blur(10px);
}
.buttonMain:hover .imgOverlay .expandingBar{
    height: 100%;
    margin: 0% 0;
}
.buttonMain:hover .expandingBar2{
    position: absolute;
    bottom: 4.875rem;
    height: 0.125rem;
    width: calc(100% - 0rem);
    left: 0;
    background-color: rgb(237,237,237,0.5);
    transition: 0.3s;
}
.imgOverlay ul{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 0;
}

.imgOverlay ul li{
    display: grid;
    align-content: center;
    text-align: left;
    list-style: none;
    text-decoration: none;
    text-wrap: wrap;
    line-height: 1.1;
    color: #fff;
    padding: 0;
    font-size: 0.9375rem;
}
.imgOverlay ul li+li{
    margin: 1.125rem 0 0 0;
}
.textContainer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    /* padding: 1.25rem 0; */
    transition: 0.3s;
    background-color: rgb(255,255,255,1);
    z-index: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.buttonMain:hover .textContainer{
    opacity: 1;
    padding: 1.25rem 2.5rem;
    background-color: rgb(255,255,255,0);
    /* background-color: rgb(237,237,237,0.75);
    backdrop-filter: blur(20px); */
}
.buttonMain .textContainer h2{
    color: rgba(0, 40, 85,1);
}
.buttonMain .textContainer p{
    color: rgba(0, 40, 85,1);
    font-weight: 300;
}
.hiq.buttonMain .textContainer h2,
.hiq.buttonMain .textContainer p{
    color: var(--uk-grey);
}
.buttonMain:hover .textContainer h2,.buttonMain:hover .textContainer p{
    color: rgba(255, 255, 255,1);
}
@media screen and (max-width: 1920px){
    .imgOverlay ul li+li{
        margin: 0.75rem 0 0 0;
    }
}
@media screen and (max-width: 1450px){
    .container{
        margin: 6rem 0 0 0;
        padding: 2.5rem calc(100cqw/12) 2.5rem calc(100cqw/12);
    }
    .buttonContainer{
        min-height: 75vh;
        background-color: rgba(255,255,255,1);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        position: relative;
        z-index: 1;
    }
    .imgOverlay ul li{
        font-size: 0.875rem;
    }
    .imgOverlay ul li+li{
        margin: 0.625rem 0 0 0;
    }
    .buttonMain p{
        font-size: 0.875rem;
    }
}
@media screen and (max-width: 1000px) {
    .container{
        margin: 6.5rem 0 0 0;
        padding: 2rem calc(100cqw/12) 2rem calc(100cqw/12);
    }
    .buttonContainer{
        min-height: 75vh;
        background-color: rgba(255,255,255,1);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        position: relative;
        z-index: 1;
    }
}
@media screen and (max-width: 750px) {
    .container{
        margin: 6.5rem 0 0 0;
        padding: calc(100cqw/12);
    }
    .buttonContainer{
        min-height: 75vh;
        background-color: rgba(255,255,255,1);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        column-gap: 2rem;
        row-gap: 1rem;
        position: relative;
        z-index: 1;
    }
    .buttonMain{
        height: 19rem;
    }
    .imgOverlay{
        padding: 1.25rem;
    }
    .buttonMain.overMobile .imgOverlay{
        opacity: 1;
        background-color: rgb(0,40,85,0.75);
        backdrop-filter: blur(10px);
    }
    .hiq.buttonMain.overMobile .imgOverlay{
        opacity: 1;
        background-color: var(--uk-grey-75);
        backdrop-filter: blur(10px);
    }
    .buttonMain.overMobile .imgOverlay .expandingBar{
        height: 100%;
        margin: 0% 0;
    }
    .buttonMain:hover .textContainer{
        opacity: 1;
        padding: 0;
        background-color: rgb(255,255,255,1);
        /* background-color: rgb(237,237,237,0.75);
        backdrop-filter: blur(20px); */
    }

    .buttonMain .textContainer h2{
        color: rgba(0, 40, 85,1);
    }
    .buttonMain:hover .textContainer h2{
        color: rgba(0, 40, 85,1);
    }
    .buttonMain:hover p{
        color: rgba(66, 66, 66,1);
    }
    .buttonMain:hover .imgOverlay{
        opacity: 0;
    }
    .buttonMain:hover .imgOverlay2{
        opacity: 0;
    }
}