.container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100cqw;
    height: 100%;
    z-index: 4;
    backdrop-filter: blur(5px);
    background-color: rgba(255,255,255,0.25);
    justify-content: center;
    align-content: center;
}

.bannerContainer{
    position: absolute;
    top: 2.5rem;
    left: calc(100cqw/12);
    right: calc(100cqw/12);
    width: calc(1000cqw/12);
    height: max-content;
    z-index: 4;
    background-color: rgba(237, 237, 237,1);
    border-radius: 1.25rem;
    padding: 2.5rem;
    justify-content: space-between;
    align-items: center;
    color: rgba(0, 40, 85,1);
    display: flex;
}
.hiq .bannerContainer{
    background-color: #fff;
    color: var(--uk-grey);
}
.policyContainer{
    position: absolute;
    top: 2.5rem;
    left: calc(100cqw/12);
    right: calc(100cqw/12);
    width: calc(1000cqw/12);
    height: max-content;
    max-height: calc(100vh - 5rem);
    z-index: 4;
    border-radius: 1rem;
    padding: 5rem;
    justify-content: space-between;
    align-items: center;
    overflow-y: auto;
    background-color: rgba(237, 237, 237,1);
    color: rgba(0, 40, 85,1)
}
.hiq .policyContainer{
    background-color: #fff;
    color: var(--uk-grey);
}
.block{
    display: block;
}
.none{
    display: none;
}
/* .policyContainer ul{
    margin: 0 0 0 2rem;
    list-style-type: square;
} */
/* .policyContainer div{
    display: flex;
} */
.policyContainer div.header{
    display: flex;
    justify-content: space-between;
}
.policyContainer div.header div{
    width: 1rem;
    height: 1rem;
    fill: rgba(0,40,85);
    cursor: pointer;
}
.hiq .policyContainer div.header div{
    fill: var(--uk-grey);
}
.policyContainer div.header div:hover{
    opacity: 0.75;
}
.policyContainer div.header div:active{
    opacity: 0.75;
}
.policyContainer div.footer{
    display: flex;
    margin: 2.5rem 0 0 0;
    justify-content: flex-end;
}
.container .policyContainer p{
    line-height: 1.5;
}
.bannerContainer div{
    display: flex;
    flex-flow: column nowrap;
}
.bannerContainer div h3, .policyContainer h3{
    margin: 0 0 0.5rem 0;
}
.hiq .bannerContainer div h3, .hiq .policyContainer h3{
    color: var(--uk-grey);
}
.bannerContainer div p {
    margin: 0 2.5rem 0 0;
    font-size: 0.875rem;
}
button.cookieButton{
    border-radius: 1.25rem;
    border: none;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    background-color: rgb(220, 220, 220);
}
button.cookieButton:hover{
    opacity: 0.75;
}
button.cookieButton:active{
    opacity: 1;
    background-color: #fff;
}
button.cookieButton.accept{
    border-radius: 1.25rem;
    border: none;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    background-color: rgb(255, 205, 0);
}
.hiq button.cookieButton.accept{
    background-color: var(--uk-cyan);
    color: #fff;
}
button.accept:hover{
    opacity: 0.75;
}
button.accept:active{
    opacity: 1;
    background-color: #fff;
}
button.cookieButton+button.cookieButton{
    margin: 0.5rem 0 0 0;
}
.policyButton{
    text-decoration: underline;
    color: rgba(0, 40, 85,1);
    cursor: pointer;
    font-size: 0.875rem;
}
.policyButton:hover{
    color: rgba(0, 40, 85,0.75);
}
@media screen and (max-width: 1000px) {
    .bannerContainer{
        flex-flow: column;
        justify-content: stretch;
        align-items: stretch;
    }
    .bannerContainer div p {
        margin: 0 0 2.5rem 0;
    }
    button.cookieButton+button.cookieButton{
        margin: 1.5rem 0 0 0;
    }
    .policyContainer{
        padding: 2.5rem;
    }
}