main {
    -webkit-container-type: inline-size; 
    container-type: inline-size;
    position: relative;
}
.mainOverlay{
    position: absolute;
    width: 100%;
    backdrop-filter: blur(0px);
    z-index: -2;
    opacity: 0;
    height: 0;
    height: 100%;
}
.changerActive{
    z-index: 3;
    opacity: 1;
    height: 100%;
    backdrop-filter: blur(20px);
}