.container{
    height: 100%;
    display: grid;
    justify-items: end;
    align-content: center;
    position: relative;
    padding: 0 0 0 1.5rem;
}
.expandingContainer{
    width: var(--container-max-width, fit-content);
    height: 2.5rem;
    background-color: rgb(222, 225, 227);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: row;
    transition: 0.3s;
    overflow: hidden;
}
.hiq .expandingContainer{
    background-color: rgba(255, 255, 255,0.15);
}
.expandingContainer.notExpanded{
    width: 2.5rem;
}
.circularIcon{
    width: 2.5rem;
    aspect-ratio: 1 / 1;
    background-color: rgba(129, 200, 239,1);
    border: 2px solid rgba(129, 200, 239,1);
    color: rgba(255, 255, 255,1);
    border-radius: 50%;
    display: grid;
    justify-items: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;
}
.expandingContainer.notExpanded .circularIcon{
    background-color: rgba(237,237,237,1);
    border: 2px solid rgba(129, 200, 239,1);
    color: rgba(129, 200, 239,1);
}
.hiq .circularIcon{
    background-color: var(--uk-cyan);
    border: 2px solid var(--uk-cyan);
    color: rgba(255, 255, 255,1);
}
.hiq .expandingContainer.notExpanded .circularIcon{
    background-color: var(--uk-grey);
    border: 2px solid var(--uk-cyan);
    color: var(--uk-cyan);
}
.nameEmailCon{
    display: grid;
    /* flex-direction: column; */
    align-content: center;
    overflow: hidden;
    margin: 0 0.875rem;
    cursor: pointer;
}

.name{
    line-height: 1;
    font-size: 0.875rem;
    font-weight:300;
    color: rgba(0, 40, 85,1);
}
.email{
    line-height: 1;
    font-size: 0.75rem;
    font-weight:600;
    color: rgba(0, 40, 85,1);
}

.logout{
    white-space: nowrap;
    overflow: hidden;
    height: 2.5rem;
    border: none;
    background-color: transparent;
    padding: 0 0.875rem;
    font-weight: 700;
    color: rgb(181,46,83);
    font-size: 0.75rem;
    cursor: pointer;
    border-radius: 1.25rem;
}
.hiq .name, .hiq .email, .hiq .logout{
    color: #fff;
}
.logout:hover{
    /* background-color: rgba(209,209,209,1); */
    opacity: 0.75;
}
@media screen and (max-width: 1450px){
    .circularIcon{
        width: 2rem;
        font-size: 0.875rem;
    }
    .expandingContainer{
        height: 2rem;
    }
    .expandingContainer.notExpanded{
        width: 2rem;
    }
    .logout{
        height: 2rem;
        font-size: 0.75rem;
    }
}
@media screen and (max-width: 1000px){
    .container{
        margin: 0;
        padding: 0;
        display: grid;
        justify-items: end;
    }
    .nameEmailCon{
        display: grid;
        align-content: center;
        justify-items: end;
        overflow: hidden;
        margin: 1rem;
    }
    .name{
        line-height: 1;
        font-size: 1rem;
        font-weight:300;
        margin: 0 0 0.25rem 0;
    }
    .email{
        line-height: 1;
        font-size: 0.75rem;
        font-weight:600;
    }
    .logout{
        white-space: nowrap;
        overflow: hidden;
        height: 3rem;
        line-height: 1;
        border: none;
        background-color: transparent;
        padding: 1rem;
        font-weight: 700;
        font-size: 1rem;
        cursor: pointer;
        border-radius: 1.25rem;
    }
    .hiq .name, .hiq .email, .hiq .logout{
        color: #fff;
    }
    .logout:hover{
        /* background-color: rgba(209,209,209,1); */
        opacity: 0.75;
    }
}