.mainColor.europe{
    background-color: rgb(0,40,85);
    color: #fff;
}
.mainColor.hiq{
    background-color: #1b323e;
    color: #fff;
}
.mainColor_NoTextStyle.mainColor.europe{
    background-color: rgb(0,40,85);
}
.mainColor_NoTextStyle.hiq{
    background-color: #1b323e;
}

.whiteBackground_textStyle.europe{
    background-color: #fff;
    color: rgb(0,40,85);
}
.whiteBackground_textStyle.hiq{
    background-color: #fff;
    color: #1b323e;
}

.accentColorButton.europe{
    background-color: rgb(255, 205, 0);
    color: #000;
}
.accentColorButton.europe:hover{
    opacity: 0.75;
    color: #000;
}
.accentColorButton.hiq{
    background-color: #00b2e3;
    color: #000;
}
.accentColorButton.hiq:hover{
    opacity: 0.75;
    color: #000;
}