*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
@import "~@flaticon/flaticon-uicons/css/all/all";
:root{
  --eu-blue: rgb(0,40,85);
  --eu-super-light-blue: rgb(230, 234, 238);
  --eu-link-blue: rgb(0, 71, 134);
  --eu-hover-blue: rgb(38, 72, 110);
  --eu-yellow: rgb(0,40,85);
  --uk-cyan: #00b5e2;
  --uk-cyan-75: rgb(0, 181, 226,0.75); /* rgb(3, 169, 244) */
  --uk-grey: #1b323e;
  --uk-grey-75: rgba(27, 50, 62,0.75);
}
html{
  font-size: 16px;
}
h1{
  font-size: 2.5rem;
  color: rgba(0, 40, 85,1);
}
h2{
  font-size: 1.5rem;
  line-height: 1;
  color: rgba(0, 40, 85,1);
}
h3{
  font-size: 1.25rem;
  line-height: 1;
  color: rgba(0, 40, 85,1);
}
@media screen and (max-width: 1000px) {
  h1{
    font-size: 1rem;
  }
}