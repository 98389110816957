main {
    -webkit-container-type: inline-size; 
    container-type: inline-size;
    position: relative;
}
.container{
    min-height: 100vh;
    margin: 8rem 0 0 0;
    padding: 4rem calc(100cqw/12) calc(100cqw/12) calc(100cqw/12);
    background-color: rgba(255,255,255,1);
    column-gap: 4rem;
    position: relative;
    z-index: 1;
}

iframe{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 75vh;
    /* background-color: #eee; */
    /* box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15); */

}
.navPageHeader{
    display: flex;
}
.navPageHeader button{
    padding: 0.875rem 1.75rem;
    background-color: rgba(0, 40, 85,1);
    border: none;
    color: #fff;
    font-weight: 600;
    border-radius: 0.5rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
}
.navPageHeader button:hover{
    opacity: 0.75;
}
.navPageHeader button:active{
    opacity: 1;
    background-color: #eee;
    color: rgba(0, 40, 85,1);
}
@media screen and (max-width: 1450px){
    .container{
        margin: 6rem 0 0 0;
        padding: 2.5rem calc(100cqw/12) 2.5rem calc(100cqw/12);

    }
}
@media screen and (max-width: 1000px) {
    .container{
        margin: 6.5rem 0 0 0;
        padding: 1rem 0 0 0;
    }
    iframe{
        /* border-radius: 0.5rem; */
        padding: 0 calc(100cqw/12 - 0.5rem);
        box-shadow: 0px 0px 0px 0px #fff;
    }
}
@media screen and (max-width: 550px) {
    .container{
        margin: 6.5rem 0 0 0;
        padding: calc(100cqw/24) 0 0 0;
    }
    iframe{
        /* border-radius: 0.5rem; */
        padding: 0 calc(100cqw/12 - 0.5rem);
        box-shadow: 0px 0px 0px 0px #fff;
    }
}