.navTabBar{
    display: flex;
    flex-flow: row;
    /* border-bottom: 2px solid #fff; */
    margin: 0 calc(100cqw/12) 0.375rem calc(100cqw/12);
    /* background-color: blue; */
    /* height: 2.875rem; */
    justify-content: center;
}
.tab{
    display: grid;
    /* background-color: blueviolet; */
    margin: 0;
    height: 100%;
    flex: 0 1 0;
    align-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    grid-template-rows: 1fr 0.125rem;
}
.tab a{
    color: rgba(182, 182, 182,1);
    text-decoration: none;
    padding: 0.5rem 1.75rem;
    font-weight: 500;
    white-space: nowrap;
    width: max-content;
    overflow: hidden;
}
.hiq .tab a{
    color: rgba(182, 182, 182,1);
}
.tab .expandingBar{
    height: 0.125rem;
    width: 10%;
    margin: 0 50%;
    opacity: 0;
    transition: 0.3s;
    background-color: rgba(0, 40, 85,0);
}
.tab:hover .expandingBar{
    height: 0.125rem;
    width: 25%;
    margin: 0 37.5%;
    background-color: rgba(66, 66, 66,0.5);
    opacity: 1;
}

.tab.selected{
    position: relative;
    background-color: #fff;
}
.tab.selected:hover{
    /* position: relative;
    color: #fff;
    background-color: rgba(0, 40, 85,0.5); */
}
.tab.selected a{
    color: rgba(0, 40, 85,1);
    font-weight: 500;
}
.hiq .tab.selected a{
    color: var(--uk-grey);
}
.tab.selected .expandingBar{
    height: 0.125rem;
    width: 75%;
    margin: 0 12.50%;
    background-color: var(--eu-blue);
    opacity: 1;
    
}
.hiq .tab.selected .expandingBar{
    background-color: var(--uk-cyan);
}
/* .tab+.tab{
    margin: 0 0 0 1rem
} */

@media screen and (max-width: 1300px) {
    .navTabBar{
        display: flex;
        flex-flow: row;
        /* border-bottom: 2px solid #fff; */
        margin: 0 2rem 0.375rem 2rem;
    }
    .tab{
        /* flex: 1 1 0; */
    }
}

@media screen and (max-width: 1000px) {
    .navTabBar{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 0.375rem;
        /* margin: 0 0 1rem 0; */
        height: max-content;
        margin: 0 calc(100cqw/4) 0.375rem calc(100cqw/4);
    }
    /* .hiq.navTabBar{
        margin: 0.375rem calc(100cqw/12) 0.375rem calc(100cqw/12);
    } */
    .tab a{
        font-size: 1rem;
        padding: 0.5rem 1rem;
        white-space: wrap;
        width: auto;
        overflow: hidden;
    }
    .tab.selected .expandingBar{
        height: 0.125rem;
        width: 75%;
        margin: 0 12.5%;
    }
}
/* @media screen and (max-width: 850px) {
    .tab.selected .expandingBar{
        height: 0.125rem;
        width: 75%;
        margin: 0 12.5%;
    }
} */
@media screen and (max-width: 700px) {
    /* .tab.selected .expandingBar{
        height: 0.125rem;
        width: 65%;
        margin: 0 17.5%;
    } */
    .navTabBar{
        margin: 0 calc(100cqw/6) 0.375rem calc(100cqw/6);
    }
}
@media screen and (max-width: 550px) {
    .tab.selected .expandingBar{
        height: 0.125rem;
        width: 75%;
        margin: 0 12.5%;
    }
    .tab a{
        padding: 0.375rem 0.5rem;
        font-size: 0.875rem;
    }
    .navTabBar{
        margin: 0 calc(100cqw/12) calc(100cqw/24 - 0.375rem) calc(100cqw/12);
    }
}