.background{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(20px);
    background-image: url('../../imgs/home/Box__Premio_Marketing.jpg');
    background-size: cover;
    background-position: center;
    z-index: 0;
    opacity: 1;
}
.hiq.background{
    background-image: url('../../imgs/home/HiQ_Logos.jpg');
}
.overlay{
    backdrop-filter: blur(7.5px);
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.paneContainer{
    height: calc(100vh - 5rem);
    margin: 2.5rem calc(100cqw/12);
    position: relative;
    z-index: 3;
    display: grid;
    justify-content: center;
    align-content: center;
}
.pane{
    border-radius: 1rem;
    width: calc(1000cqw/12);
    height: max-content;
    max-height: calc(100vh - 5rem);
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
}
.pane h1{
    color: #fff;
    text-align: center;
}
.paneUpper{
    background-color: rgba(0, 40, 85,1);
    display:grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 2.5rem;
    /* height: 20vh; */
}
.paneLower{
    width: 100%;
    padding: 5rem;
    background-color: rgb(237,237,237);
    color: rgb(0,40,85);
}
.hiq .paneLower{
    background-color: #fff;
    color: var(--uk-grey);
}
.paneLower h3, .paneLower h4, .paneLower strong{
    color: rgba(0, 40, 85,1);
}
.hiq .paneLower h3, .hiq .paneLower h4, .hiq .paneLower strong{
    color: var(--uk-grey);
}
.paneLower ul{
    margin: 0 0 0 2rem;
    list-style-type: square;
}
.returnBtn{
    width: max-content;
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 1.5rem 0 0 0;
    font-weight: 600;
    cursor: pointer;
    background-color: rgb(255, 205, 0);
    color:#000;
    float: right;
}
.returnBtn:hover{
    opacity: 0.75;
    color: #000;
}
.hiq .returnBtn{
    background-color: var(--uk-cyan);
    color: #fff;
}
@media screen and (max-width:1000px){
    .paneLower{
        padding: 2.5rem;
    }
}