main {
    -webkit-container-type: inline-size; 
    container-type: inline-size;
    position: relative;
}
.container{
    min-height: 100vh;
    margin: 8rem 0 0 0;
    padding: 4rem calc(100cqw/12) calc(100cqw/12) calc(100cqw/12);
}
.newUser{
    position: absolute;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(20px);
    display: none;
}
.newUser.newFormOpen{
    display: block;
}
.changerPane{
    position: absolute;
    top: 25vh;
    width: 50cqw;
    left: 25cqw;
    background-color: rgba(237, 237, 237,1);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.25);
    border-radius: 1.25rem;
    padding: 2.5rem;
    display: block;
}
.hiq .changerPane{
    background-color: rgba(255, 255, 255,1);
}
.changerPane.newFormOpen{
    /* margin: 20vh calc(200cqw/12);
    width: calc(800cqw/12);
    height: 60vh; */
    display: grid;
    grid-template-rows: 1.5rem 1fr 4rem;
    row-gap: 1rem;
}
.changerPane h2,.changerPane h3{
    color: var(--eu-blue);
}
.hiq .changerPane h2,.hiq .changerPane h3{
    color: var(--uk-grey);
}

.changerPane h3{
    margin: 2rem 0 1rem 0;
}
.formInner{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2.5rem;
    row-gap: 0.5rem;

}
.rolesCon{
    grid-area: 1 / 2 / 3 / 3;
}
.nameInputs{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}
.nameInputs p.emailMsg{
    color: rgb(255, 0, 0);
    text-align: end;
}
.changerPane label{
    color: var(--eu-blue);
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.hiq .changerPane label{
    color: var(--uk-grey);
}
.changerPane label p{
    line-height: 1.2;
    text-align: end;
}
.changerPane label input{
    margin: 0 0 0 1rem;
    height: 2rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    background-color: rgb(209,209,209);
}
.hiq .changerPane label input{
    background-color: rgb(237,237,237);
}

.changerPane label select{
    margin: 0 0 0 1rem;
    height: 2rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    background-color: rgb(209,209,209);
}
.hiq .changerPane label select{
    background-color: rgb(237,237,237);
}
.changerPane label select:disabled{
    opacity: 0.25;
}
.changerPane .userRoles label {
    grid-template-columns: 2fr 1fr;
    align-items: center;
}

.inviteCode{
    grid-column: 1/4;
    grid-row: 2/3;
    display: flex;
}
.inviteCode input{
    width: 100%;
}
.userRoles{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    grid-template-rows: 2rem 2rem;
    row-gap: 1rem;
}
.defaults{
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
}
.footer{
    margin: 1.5rem 0 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: end;
}
.footer input{
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    background-color: rgb(255, 205, 0);
}
.hiq .footer input{
    color: #fff;
    background-color: var(--uk-cyan);
}
.footer input:hover{
    opacity: 0.75;
}
.footer input.cancel{
    color: #000;
    background-color: rgb(200, 200, 200);
    margin: 0 1rem 0 0;
}
.addButton{
    border-radius: 1.25rem;
    border: none;
    height: 2.5rem;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
    margin: 1.5rem 0;
    font-weight: 600;
    cursor: pointer;
    background-color: rgb(255, 205, 0);
}
.hiq .addButton{
    color: #fff;
    background-color: var(--uk-cyan);
}
.hiq h2{
    color: var(--uk-grey);
}
@media screen and (max-width: 1450px){
    .container{
        min-height: 100vh;
        margin: 6rem 0 0 0;
    }
}
@media screen and (max-width: 1000px){
    .container{
        min-height: 100vh;
        margin: 6.5rem 0 0 0;
        padding: 0 calc(100cqw/12) calc(100cqw/12) calc(100cqw/12);
    }
    .pageContainer{
        max-width: 100vw;
    }
    .scrollDiv{
        width: calc(1000cqw/12);
        overflow-x: scroll;
    }
}