

header{
    position: fixed;
    left: 0;
    height: 4rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12,1fr);
    z-index: 5;
    transition: 2s;
    background-color: #fff;
    background-color: rgb(237,237,237);
    backdrop-filter: blur(15px);
    align-content: center;
}
header.hiq{
    background-color: var(--uk-grey);
}

.headerTextWhite ,
.headerTextWhite .imgContainer svg g,
.headerTextWhite .burgerMenuButton svg rect,
.headerTextWhite nav ul li a{
    color: rgb(0,40,85);
    fill: rgb(0,40,85);
    /* color: #fff;
    fill:#fff; */
}
.hiq.headerTextWhite ,
.hiq.headerTextWhite .imgContainer svg g,
.hiq.headerTextWhite .burgerMenuButton svg rect,
.hiq.headerTextWhite nav ul li a{
    color: #fff;
    fill: #fff;
    /* color: #fff;
    fill:#fff; */
}
header .imgContainer{
    height: 2rem;
    grid-column: 2/7;
    margin: 1rem 0;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 300;
    align-content: center;
}
header .imgContainer strong{
    font-weight: 700;
}

header.hiq .imgContainer{
    display: flex;
    flex-flow: row nowrap;
    justify-items: center;
    align-items: center;
}
.hiq .imgContainer span {
    display: inline-block;
    line-height: 1;
}
.hiq .imgContainer div.divider{
    background-color: #fff;
    height: 2rem;
    width: 0.125rem;
    margin: 0 0 0 1.125rem;
    opacity: 0.5;
}
.hiq .imgContainer div.svgCon {
    height: 3rem;
}
.hiq .imgContainer div.svgCon svg{
    height: 3rem;
}

header .burgerMenuButton{
    grid-column: 11/12;
}
/* header .burgerMenuButton i{
    display: block;
    font-size: 2rem;
    line-height: 1;
    height: 2rem;
    width: 2rem;
    margin: 1rem 0;
    z-index: 2;
} */
header .burgerMenuButton svg{
    display: block;
    font-size: 2rem;
    line-height: 1;
    height: 1rem;
    width: 1rem;
    margin: 1rem 0;
    z-index: 2;
}
header nav{
    grid-column: 7/12;
}
header nav ul {
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
    z-index: 2;

}
header nav ul li {
    display: block;
    text-decoration: none;
    /* min-width: 4rem; */
}
header nav ul li a{
    display: block;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 1.5rem;
    line-height: 1;
    cursor: pointer;
    transition: 0.2s;
}
header nav ul li a:hover{
    opacity: 0.75;
}
.closeButton{
    display: none;
}
@media screen and (max-width: 1450px){
    header{
        height: 3rem;
    }
    header .imgContainer{
        height: 1.125rem;
        grid-column: 2/7;
        margin: 0.9375rem 0;
        font-size: 1rem;
    }
    header .imgContainer svg{
        height: 1.125rem;
        width: auto;
    }
    header nav ul li a{
        font-size: 0.875rem;
        padding: 1.0625rem;
    }
    .hiq .imgContainer span {
        display: inline-block;
        line-height: 1;
    }
    .hiq .imgContainer div.divider{
        background-color: #fff;
        height: 1.625rem;
        width: 0.125rem;
        margin: 0 0 0 1rem;
        opacity: 0.5;
    }
    .hiq .imgContainer div.svgCon {
        height: 2.4rem;
    }
    .hiq .imgContainer div.svgCon svg{
        height: 2.4rem;
    }
}
@media screen and (max-width: 1000px) {
    header{
        height: 3rem;
    }
    header .imgContainer{
        height: 3rem;
        margin: 0;
        grid-column: 2/11;
        display: grid;
        align-content: center;
        font-size: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    header .imgContainer svg{
        height: 1rem;
        width: auto;
        margin: 0;
    }
    header nav ul {
        position: fixed;
        top: 0rem;
        right: 0rem;
        display: flex;
        backdrop-filter: blur(20px);
        flex-flow: column nowrap;
        padding: 0 0 1rem 0;
        box-shadow: -1px 1px 50px 1px rgba(0,0,0,0.5);
        z-index: 3;
    }
    header.hiq nav ul {
        background-color: var(--uk-grey);
    }
    header nav ul li a{
        display: block;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 700;
        color: rgba(0, 40, 85,1);
        padding: 1rem;
        line-height: 1;
        cursor: pointer;
        transition: 0.2s;
        text-align: right;
        /* background-color: blue; */
    }
    header nav ul li a:hover{
        color: rgba(255,255,255,0.85);
    }
    header nav ul li a:active{
        background-color: #eee;
        color: rgba(0, 40, 85,1);
    }
    
    .closeButton{
        display: block;
    }
    header nav ul li.closeButton a{
        display: grid;
        justify-items: end;
        align-items: center;
        padding: 0 1rem;
        height: 3rem;
        /* background-color: red; */
        margin: 0 0 0 0;
    }
    header nav ul li.closeButton a svg{
        height: 1rem
    }
    header nav ul {
        background-color: rgb(237, 237, 237);
    }
    .headerTextWhite nav ul li a{
        color: rgba(0, 40, 85,1);
        fill:rgba(0, 40, 85,1);
    }
    .hiq .imgContainer{
        margin: 0;
        padding: 0;
    }
    .hiq .imgContainer span {
        display: inline-block;
        line-height: 1;
    }
    .hiq .imgContainer div.divider{
        display: block;
        background-color: #fff;
        height: 1.625rem;
        width: 0.0625rem;
        margin: 0 0 0 1rem;
        opacity: 0.5;

    }
    .hiq .imgContainer div.svgCon {
        height: 2.4rem;
    }
    .hiq .imgContainer div.svgCon svg{
        height: 2.4rem;
    }

}
@media screen and (max-width: 400px) {
    .hiq .imgContainer div.divider{
        display: none;
    }
    .hiq .imgContainer div.svgCon {
        display: none;
    }

}
.displayFlex{
    display: flex;
}
.displayBlock{
    display: block;
}
.displayGrid{
    display: grid;
    justify-content: end;
}
.hide{
    display: none;
}