.container,.containerHeader{
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr 1fr;
}
.container{
    height: 4.75rem;
    line-height: 1;
    padding: 0rem 0;
    /* background-color: #ccc; */
}
.containerHeaderHeader{
    display: grid;
    grid-template-columns: 2fr 5fr 1fr;
    margin: 0 0 0 0;
    justify-items: center;
}
.permissionsHeader{
    font-size: 0.875rem;
    display: grid;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    width: 100%;
}
.permissions{
    display: grid;
    justify-content: center;
    padding: 0.25rem 0.5rem;
}
.container.viewOnly,.containerHeader.viewOnly{
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.containerHeader{
    font-size: 0.875rem;
    justify-content: start;
    align-items: end;
    padding: 0 0 0 0;
    border-bottom: 0.125rem solid rgb(0, 40, 85);
}
.containerHeader div{
    padding: 0.25rem 0.5rem;
    overflow: hidden;
    text-overflow: clip;
    height: 100%;
    word-wrap: break-word;
    display: grid;
    align-content: center;
}
.hiq.containerHeader{
    border-bottom: 0.125rem solid var(--uk-grey);
}
.container+.container{
    border-top: 2px solid #eee;
}
.container div,.containerHeader div{
    /* padding: 0.75rem 0.5rem; */
}
.eu{
    color: #fff;
    background-color: var(--eu-blue);
    text-align: center;
}
.uk{
    text-align: center;

    color: var(--eu-blue);
    background-color: #fff;
}
.hiq .eu{
    color: #000;
    background-color: #fff;
}
.hiq .uk{
    color: #fff;
    background-color: var(--uk-grey);
}
.containerHeader div.nameEmailCount{
    padding: 0;
}
.container button{
    height: 1.5rem;
    border-radius: 0.75rem;
    border: none;
    margin: 0.5rem 0.25rem;
    cursor: pointer;
    background-color: #eee;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.container button:hover{
    background-color: #ddd;
}
.container button.delete{
    background-color: rgb(255, 0, 0);
}
.container button.delete:hover{
    background-color: rgb(200, 0, 0);
}
.container button:active,.container button.delete:active{
    background-color: hsl(0, 0%, 98%);
}
.container div.inviteLink{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    height: 2rem;
    /* background-color: red; */
    padding: 0;
}
.container div.inviteLink p{
    margin: 0.875rem;
    line-height: 1;
    overflow: hidden;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.container div.inviteLink button{
    margin: 0.5rem 0.25rem;
    line-height: 1;
    padding: 0.25rem 1rem;
}
.container div.small{
    /* padding: 0.875rem; */
    line-height: 1;
    overflow: hidden;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.nameEmail{
    display: grid;
    grid-template-rows: 1.25rem 1rem 1rem;
    row-gap: 0.25rem;
    padding: 0.25rem 1rem 0.25rem 0;
    align-content: center;
}
.nameEmail div{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
}
.checkContainer{
    display: grid;
    justify-content: center;
    align-content: center;
}
.container div i{
    color: green;
    font-size: 1.5rem;
    padding: 0.5rem;
}
.checkContainer svg{
    height: 1.5rem;
    width: 1.5rem;
}
.checkContainer svg path{
    fill: green;
}
.checkContainer svg line{
    stroke: green;
}

.checkContainer.eu{
    color: #fff;
    background-color: #fff;
}
.checkContainer.eu.left{
    border-left: 0.125rem dashed var(--eu-blue);
}
.checkContainer.eu.right{
    border-right: 0.125rem dashed var(--eu-blue);
}
.checkContainer.eu svg path{
    fill: var(--eu-blue);
}
.checkContainer.eu svg line{
    stroke: var(--eu-blue);
}

.checkContainer.uk{
    color: var(--eu-blue);
    background-color: #fff;
}
.checkContainer.uk svg path{
    fill: var(--eu-blue);
}
.checkContainer.uk svg line{
    stroke: var(--eu-blue);
}

.hiq .checkContainer.eu{
    color: #fff;
    background-color: #fff;
}
.hiq .checkContainer.eu.left{
    border-left: none;
}
.hiq .checkContainer.eu.right{
    border-right: none;
}
.hiq .checkContainer.eu svg path{
    fill: var(--uk-grey);
}
.hiq .checkContainer.eu svg line{
    stroke: var(--uk-grey);
}

.hiq .checkContainer.uk{
    color: var(--uk-grey);
    background-color: #fff;
    border-left: 0.125rem dashed var(--uk-grey);
    border-right: 0.125rem dashed var(--uk-grey);
}
.hiq .checkContainer.uk svg path{
    fill: var(--uk-grey);
}
.hiq .checkContainer.uk svg line{
    stroke: var(--uk-grey);
}

.defaults{
    display: grid;
    justify-content: start;
    overflow: hidden;
}
.brandCountry{
    margin: 0;
    height: 2.5rem;
    background-color: rgba(0, 40, 85,1);;
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-end;
}
.logoContainer{
    height: 2.5rem;
    border-radius: 0.5rem;
    padding: 0.25rem;
    overflow: hidden;
    background-color: rgba(0, 40, 85,1);
}
.logoContainer svg{
    height: 2rem;
}
.brandCountryTextCon{
    flex-flow: column;
    padding: 0 1rem 0 0;
}
.countryText{
    color: #fff;
    font-size: 0.75rem;
    padding: 0.4375rem 0 0.0625rem 0;
}
.langText{
    color: #fff;
    font-size: 0.75rem;
    padding: 0.0625rem 0 0.4375rem 0;
}
@media screen and (max-width: 1000px){
    .permissionsHeader, .permissions, .containerHeader div{
        font-size: 0.625rem;
        /* text-align: left; */
        word-wrap: break-word;
        padding: 0.25rem 0;
    }
    .nameEmail{
        display: grid;
        grid-template-rows: 1rem 1rem 1rem;
        row-gap: 0.25rem;
        padding: 0.25rem 1rem 0.25rem 0;
        align-content: center;
    }
    .nameEmail div{
        font-size: 0.75rem;
    }
    .container{
        height: 4.5rem;
        min-width: 400px;
    }
    .containerHeader,.containerHeaderHeader{
        min-width: 400px;
    }
    .container.viewOnly,.containerHeader.viewOnly{
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    
}
.containerEdit{
    display: grid;
    height: 4.625rem;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr 1fr 1fr;
    background-color: #fff;
    margin: 0.125rem -1.25rem 0.125rem -1.25rem;
    padding: 0.25rem 1.25rem 0.25rem 1.25rem;
    border-radius: 1.25rem;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.15);
}
.container+.containerEdit{
    border-top: none;
}
.containerEdit+.container{
    border-top: none;
    height: 4.625rem;
}
/*
    height: 4.75rem;
    line-height: 1;
    padding: 0.25rem 0;
*/
.containerEdit select{
    display: block;
    height: 1.5rem;
    border-radius: 0.5rem;
    padding: 0.125rem 0.375rem;
    border: none;
    background-color: rgba(0, 40, 85,1);
    color: #fff;
    line-height: 1;
    width: 100%;
    grid-column: 1/2;
}
.containerEdit .options{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0.5rem 0.5rem 0.5rem 0;
    column-gap: 0.5rem;
}
.containerEdit .options button{
    margin: 0;
}
.containerEdit .nameEmail input{
    display: block;
    height: 1.25rem;
    border-radius: 0.5rem;
    padding: 0.125rem 0.375rem;
    border: none;
    background-color: rgba(0, 40, 85,1);
    color: #fff;
    line-height: 1rem;
    width: 100%;
    grid-column: 1/2;
}
.containerEdit .nameEmail .small{
    padding: 0.25rem 0.375rem;
}
.containerEdit .options2{
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 0.5rem 0.5rem 0.5rem 0;
}
.containerEdit .options2 button{
    margin: 0;
}