.contentContainer{
    display: block;
    height: max-content;
}
.contentContainer p{
    font-size: 0.875rem;
    padding: 0 0 0 1.25rem;
    margin: 0 0 0.25rem 0;
}
.contentContainer div{
    margin: 0 0 1.25rem
}
.contentContainer ul{
    font-size: 0.875rem;
}
.contentContainer h3, .contentContainer h4, .contentContainer strong{
    margin: 0 0 0.25rem 0;
}
.contentContainer span{
    font-style: italic;
    font-weight: 600;
}
.contentContainer ul{
    margin: 0 0 0.25rem 2.5rem;
    list-style-type: square;
}
.background{
    min-height: 50vh;
    width: 100%;
    backdrop-filter: blur(20px);
    background-size: cover;
    background-position: top;
    z-index: 0;
    opacity: 1;
}
@media screen and (max-width: 1000px){
    .containcontentContainerer{
        margin: 6.5rem 0 0 0;
        padding: calc(100cqw/12) calc(100cqw/12) calc(100cqw/12) calc(100cqw/12);
    }
}